import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function usePinnedFeatureUsage({ pinnedFeatures, orgId, accountName }) {
  const navigate = useNavigate();
  const [graphType, setGraphType] = useState('users');

  const handleSelection = (event) => {
    setGraphType(event.target.value);
  };
  const [stackHeight, setStackHeight] = useState(350);
  useEffect(() => {
    let newStackHeight = 350;
    const pinnedFeaturesLength = pinnedFeatures?.data?.length;

    if (pinnedFeaturesLength === 1) {
      newStackHeight = graphType === 'users' ? 330 : 355;
    } else if (pinnedFeaturesLength === 2) {
      newStackHeight = graphType === 'users' ? 670 : 720;
    } else if (pinnedFeaturesLength >= 3) {
      newStackHeight = graphType === 'users' ? 1015 : 1085;
    }

    setStackHeight(newStackHeight);
  }, [pinnedFeatures, graphType]);

  const handleClick = () => {
    if (accountName && orgId) {
      navigate(`/account-page/feature-usage?orgId=${orgId}`, { state: { accountName, orgId } });
    } else {
      navigate('/feature-usage');
    }
  };
  return {
    handleSelection, graphType, handleClick, stackHeight,
  };
}
