import { generateUrl } from '../modules/Request/proxy.config';
import { ENV } from '../config';

const fetchWithAuth = async (path, options = {}) => {
  const { body, headers: customHeaders, ...rest } = options;

  const token = localStorage.getItem('idToken');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    ...options.headers,
  };

  const url = generateUrl(path, ENV);
  const response = await fetch(url, {
    ...rest,
    headers,
    body: body && typeof body === 'object' ? JSON.stringify(body) : body,
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

export { fetchWithAuth };
