import { useNavigate } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { withLoadingAndOverlay } from '../../../hoc';
import { Graph } from '../../../components/Graph';

function LicenseUtilizationCard({ data = {} }) {
  const navigate = useNavigate();

  const labels = Object.keys(data);

  const datasets = [
    {
      label: 'Accounts',
      data: Object.values(data),
      name: labels,
      hoverOffset: 4,
      backgroundColor: ['#f3bc00', '#4482FF', '#31e0c4', '#294E99', '#00af82'],
      borderColor: ['#f3bc00', '#4482FF', '#31e0c4', '#294E99', '#00af82'],
    },
  ];

  const options = {
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedIndex = elements[0].index;
        const clickedLabel = labels[clickedIndex];
        navigate('/account-list', { state: { filters: { licenceAssignment: [clickedLabel], licenceStatus: ['Active'], accountVisibility: true } } });
      }
    },
    maintainAspectRatio: false,
    responsive: false,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const sets = ctx.chart.data.datasets;
          if (sets.indexOf(ctx.dataset) === sets.length - 1) {
            const sum = sets[0].data.reduce((a, b) => a + b, 0);
            const perValue = Math.round((value / sum) * 100);
            const percentage = perValue ? `${perValue}%` : '';
            return percentage;
          }
          return true;
        },
        color: '#fff',
        display: true,
      },
      legend: {
        position: 'bottom',
        align: 'center',
        labels: {
          padding: 15,
        },
        onHover(e) {
          const { style } = e.native.target;
          style.cursor = 'pointer';
        },
        onLeave(e) {
          const { style } = e.native.target;
          style.cursor = 'default';
        },
      },
    },
  };

  return (
    <Graph
      type="pie"
      labels={labels}
      datasets={datasets}
      options={options}
      plugins={[ChartDataLabels]}
      height={350}
    />
  );
}

export default withLoadingAndOverlay(LicenseUtilizationCard);
