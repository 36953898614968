/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded';
import { Typography } from '@mui/material';
import { ClickableCell } from '../../components/CustomDataGrid';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { disablePackage, getPackageDetailList } from '../../features';
import useToggle from '../../hooks/useToggle';
import palette from '../../theme/palette';
import { ActionCell } from '../../modules/FeatureManager';

function usePackageManager() {
  // handle api data
  const packageDetailList = useSelector((state) => state.packageDetailList);
  const [packageList, setPackageList] = useState({
    loading: true,
    error: null,
    data: [],
  });

  const setTenantsData = () => {
    setPackageList({
      ...packageList,
      ...packageDetailList,
    });
  };

  useEffect(() => {
    setTenantsData();
  }, [packageDetailList]);

  // states and functions
  const dispatch = useDispatch();
  const [openActionDialog, setOpenActionDialog] = useToggle();
  const [actionDialogData, setActionDialogData] = useState({});

  const handleDisableTenant = (params, string) => {
    const disableTenantFn = () => {
      const activePackageData = localStorage.getItem('activePackage');
      if (activePackageData) {
        const activePackageName = JSON.parse(activePackageData);
        const { packageId } = activePackageName;
        if (packageId !== params.row.packageId) {
          dispatch(disablePackage({ packageId: params.row.packageId })).then((res) => {
            if (res?.payload?.status === 'SUCCESS') {
              dispatch(getPackageDetailList());
              dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
              window.location.reload();
            } else {
              const msg = `Failed to ${string.toLowerCase()} package!`;
              dispatch(openSnackbar({ open: true, message: res?.payload?.message ?? msg, type: 'error' }));
            }
          });
        } else {
          dispatch(openSnackbar({ open: true, message: 'Selected package has not been disabled.', type: 'error' }));
        }
      } else {
        dispatch(disablePackage({ packageId: params.row.packageId })).then((res) => {
          if (res?.payload?.status === 'SUCCESS') {
            dispatch(getPackageDetailList());
            dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
            window.location.reload();
          } else {
            const msg = `Failed to ${string.toLowerCase()} package!`;
            dispatch(openSnackbar({ open: true, message: res?.payload?.message ?? msg, type: 'error' }));
          }
        });
      }
      setOpenActionDialog();
    };

    setActionDialogData({
      title: `${string} Package`,
      message: (
        <Typography>
          {`Are you sure you want to ${string.toLowerCase()} Package`}
          {' '}
          <span style={{ color: palette.primary.main }}>{params.row.packageLabel}</span>
          {' ?'}
        </Typography>
      ),
      onConfirm: disableTenantFn,
    });
    setOpenActionDialog();
  };

  // datagrid columns
  const getActionCellData = (params) => {
    const { isEnabled } = params.row;
    const string = isEnabled ? 'Disable' : 'Enable';

    const actionCellData = [
      {
        id: 'disable',
        info: `${string} Tenant`,
        icon: isEnabled ? CloudDoneRoundedIcon : CloudOffIcon,
        handleFunction: (param) => handleDisableTenant(param, string),
      },
    ];

    return actionCellData;
  };

  const packageColumns = [
    {
      field: 'packageLabel',
      headerName: 'Package',
      flex: 1,
      isRowSelectable: true,
      renderCell: (params) => <ClickableCell value={params.value} />,
    },
    {
      field: 'isEnabled',
      headerName: 'Status',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      isRowSelectable: false,
      renderCell: (params) => (
        <span style={{
          fontWeight: 500,
          color: params.row.isEnabled
            ? palette.success.dark
            : palette.warning.alt,
        }}
        >
          {params.row.isEnabled ? 'Enabled' : 'Disabled'}
        </span>
      ),
    },
    {
      field: 'appAnalyticsStatus',
      headerName: 'App Analytics Status',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      isRowSelectable: false,
      renderCell: (params) => (
        <span style={{
          fontWeight: 500,
          color: params.row.appAnalyticsStatus === 'Active'
            ? palette.success.dark
            : palette.warning.alt,
        }}
        >
          {params.row.appAnalyticsStatus}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.8,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      isRowSelectable: false,
      renderCell: (params) => <ActionCell actionCellData={getActionCellData(params)} params={params} />,
    },
  ];

  return {
    packageList,
    packageColumns,
    openActionDialog,
    setOpenActionDialog,
    actionDialogData,
  };
}

export default usePackageManager;
