import {
  Box, Typography, Stack, Tooltip as MuiTooltip,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TrendingDown, TrendingUp, TrendingFlat } from '@mui/icons-material';
import { formatDistance, isBefore, isEqual } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Chart } from 'react-chartjs-2';
import { useLayoutEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { identifyAndConvertDate } from '../../../utils/formatTime';
import { DATE_FORMAT } from '../../../utils/constants';
import { explainHealthScore } from '../../../features/HealthScore/HealthScoreAction';
import { HealthScoreExplain } from '../../../modules/HealthScore';
import useToggle from '../../../hooks/useToggle';
import { CustomDataGrid } from '../../../components/CustomDataGrid';
import styles from './style';
import useStyles from '../../../hooks/useStyles';
import { columns } from './accountsConstants';
import { getDate, getMomentDate, isAfter90Days } from '../../../utils/getDate';
import { CustomCircularProgress } from '../../../components/CustomCircularProgress';

const MainContainerStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  '& div[aria-rowindex]': {
    borderBottom: '1px solid #eee',
  },
  '& > div': {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.mainColor,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

const normalizedValue = (value) => (value !== null ? Math.min(Math.max(value, 0), 100) : 0);

export default function AccountDetailsTable({
  AccountTableData, packageId, permissions, customFilterData,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useToggle();
  const [selectedHealthScore, setSelectedHealthScore] = useState(null);

  const handleClickScore = (orgId, score, healthScoreId) => {
    setOpen();
    setSelectedHealthScore(score);
    if (orgId && healthScoreId) {
      dispatch(explainHealthScore({ orgId, packageId, healthScoreId }));
    } else if (orgId) {
      dispatch(explainHealthScore({ orgId, packageId }));
    }
  };

  const columnInfo = columns((customFilterData?.[0]?.view === 'weekly') ? 'weeklyTrendValue' : 'trendValue');
  columnInfo.forEach((item) => {
    if (item.field === 'accountName') {
      item.renderCell = (params) => <AccountNameCell type="account" params={params} />;
    } else if (item.field === 'status') {
      item.renderCell = (params) => <AccountStatusCell params={params} />;
    } else if (item.field === 'renewalDate') {
      item.renderCell = (params) => <RenewalDateCell params={params} />;
    } else if (item.field === 'seatsAssigned') {
      item.renderCell = (params) => <SeatsCell params={params} />;
    } else if (item.field === 'healthScore') {
      item.renderCell = (params) => <HealthScore isAccountHealthScore params={params} onClick={() => handleClickScore(params.row.orgId, normalizedValue(params.row.healthScore), params.row.healthScoreId)} />;
    } else if (item.field === 'healthScoreTrendThumbnail') {
      item.renderCell = (params) => <HealthScoreTrendThumbnail view={customFilterData?.[0]?.view ?? 'monthly'} trendsData={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendsData || []) : (params?.row?.trendsData || [])} />;
    } else if (item.field === 'trendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    } else if (item.field === 'weeklyTrendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    }
    return false;
  });

  const initialState = {
    sorting: {
      sortModel: [{ field: 'seatsAssigned', sort: 'desc' }],
    },
  };

  return (
    <MainContainerStyle>
      <CustomDataGrid
        rowId="orgId"
        initialState={initialState}
        columns={columnInfo}
        rows={AccountTableData || []}
        rowHeight={70}
        rowsPerPageOptions={[20]}
        pageSize={20}
        loading={!AccountTableData}
      />
      <HealthScoreExplain open={open} setOpen={setOpen} permissions={permissions} healthScore={selectedHealthScore} />
    </MainContainerStyle>
  );
}

const trendIcon = (trendType, trendValue) => {
  if (trendType === 'rising') {
    return (
      <MuiTooltip title="Trending">
        <Typography sx={{
          display: 'flex', flexDirection: 'row', color: 'green', alignItems: 'center',
        }}
        >
          <TrendingUp />
          {trendValue
            ? (
              <Typography sx={{ fontSize: 13 }}>
                {` +${trendValue}`}
              </Typography>
            )
            : null}
        </Typography>
      </MuiTooltip>
    );
  }
  if (trendType === 'falling') {
    return (
      <MuiTooltip title="Declining">
        <Typography sx={{
          display: 'flex', flexDirection: 'row', color: 'red', alignItems: 'center',
        }}
        >
          <TrendingDown />
          {trendValue
            ? (
              <Typography sx={{ fontSize: 13 }}>
                {` ${trendValue}`}
              </Typography>
            )
            : null}
        </Typography>
      </MuiTooltip>
    );
  }
  if (trendType === 'stable') {
    return (
      <MuiTooltip title="Stable">
        <Typography sx={{
          display: 'flex', flexDirection: 'row', color: 'gray', alignItems: 'center',
        }}
        >
          <TrendingFlat />
        </Typography>
      </MuiTooltip>
    );
  }
  return null;
};

export function AccountSignalCell({ healthScoreTrend, trendValue }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {healthScoreTrend
        ? (
          <Typography>
            {trendIcon(healthScoreTrend, trendValue)}
          </Typography>
        )
        : null }

    </Stack>
  );
}

export function AccountNameCell({ params, type = '' }) {
  const classes = useStyles(styles)();

  const navigateTo = {
    pathname: '/account-page',
    search: `?orgId=${params.row.orgId}&pkgId=${params.row.packageId}`,
    state: { accountName: params.value, orgId: params.row.orgId },
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <AccountNameColumn type={type} data={params} link={navigateTo} />

      {params?.row?.installDate && isAfter90Days(params.row.installDate)
        ? (
          <Typography className={classes.cellSubNewTextStyle}>
            New
          </Typography>
        )
        : null}
      {!params?.row?.visibility
        ? (
          <Typography className={classes.cellSubTextStyle}>
            {params.row.visibility ? null : ' (Excluded)'}
          </Typography>
        ) : null }
    </Stack>
  );
}
function AccountNameEllipsis(props) {
  const {
    value, name, number, link,
  } = props;
  return (
    <MuiTooltip title={value}>
      <TypographyStyle
        component={RouterLink}
        to={link}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
      >
        {`${name.slice(0, number)}...`}
      </TypographyStyle>
    </MuiTooltip>
  );
}
function AccountNameColumn(props) {
  const {
    data, type, link,
  } = props;
  const name = data?.value || data?.row?.orgId;
  const isNew = data?.row?.installDate && isAfter90Days(data.row.installDate);
  if (!type && (data?.row?.healthScoreTrend !== 'stable') && (name?.length > 9)) {
    return (
      <AccountNameEllipsis value={data.value} name={name} number={9} link={link} />
    );
  }
  if ((data?.row?.healthScoreTrend !== 'stable') && (isNew ? name?.length > 11 : name?.length > 16)) {
    return (
      <AccountNameEllipsis value={data.value} name={name} number={isNew ? 11 : 16} link={link} />
    );
  }
  return (
    <TypographyStyle component={RouterLink} to={link}>
      {name}
    </TypographyStyle>
  );
}

function AccountStatusCell({ params }) {
  const classes = useStyles(styles)();
  const status = params.row.status !== 'Uninstalled' ? params.row.status : 'Churned';
  const createdDate = identifyAndConvertDate(params.row.activationDate ? params.row.activationDate.slice(0, 10) : null, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD');
  return (
    <Box className={classes.cellBoxStyle}>
      <Typography className={classes.cellTextStyle}>{status}</Typography>
      {status === 'Active' && <Typography className={classes.cellSubTextStyle}>{`From ${createdDate}`}</Typography>}
    </Box>
  );
}

export function RenewalDateCell({ params }) {
  const classes = useStyles(styles)();
  const renewalDate = identifyAndConvertDate(params.row.renewalDate ? params.row.renewalDate.slice(0, 10) : null, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD');
  const todayDate = getDate();
  const renewDate = getDate(renewalDate);
  let renewalStatus = '';
  if (isEqual(todayDate, renewDate)) {
    renewalStatus = 'today';
  } else if (isBefore(todayDate, renewDate)) {
    renewalStatus = formatDistance(renewDate, todayDate, { addSuffix: true });
  } else {
    renewalStatus = renewalDate ? 'expired' : 'NA';
  }
  return (
    <Box className={classes.cellBoxStyle}>
      {params.row.renewalDate === null
        ? <Typography className={classes.cellTextStyle}>Does Not Expire</Typography>
        : (
          <>
            <Typography className={classes.cellTextStyle}>{renewalDate}</Typography>
            <Typography className={classes.cellSubTextStyle}>{renewalStatus}</Typography>
          </>
        )}
    </Box>
  );
}

export function SeatsCell({ params }) {
  const classes = useStyles(styles)();
  if (params?.row?.totalSeats && (parseInt(params?.row?.totalSeats, 10)) > 0) {
    const assignedSeatsPercent = parseFloat(params.row.assignedSeatsPercent).toFixed(0);
    return (
      <Box className={classes.cellBoxStyle}>
        <Typography className={classes.cellTextStyle}>{params.row.seatsAssigned}</Typography>
        {assignedSeatsPercent !== 'NaN' ? (
          <Typography className={classes.cellSubTextStyle}>{`${(assignedSeatsPercent > 100 ? '100% assigned*' : `${assignedSeatsPercent}% assigned`)}`}</Typography>
        ) : null}
      </Box>
    );
  } return (
    <Stack spacing={0.5}>
      <Typography className={classes.cellSubTextStyle}>Site-Wide License</Typography>
    </Stack>
  );
}

export function HealthScore({ params, onClick, isAccountHealthScore = false }) {
  const { healthScore } = params.row;
  return (
    <Box onClick={onClick} sx={{ cursor: isAccountHealthScore ? 'pointer' : 'auto' }}>
      <CustomCircularProgress value={normalizedValue(healthScore)} />
    </Box>
  );
}

export function HealthScoreTrendThumbnail({ trendsData = [], view = 'monthly' }) {
  // const navigate = useNavigate();
  const chartData = {
    labels: trendsData.map((data) => data.startDate),
    datasets: [
      {
        type: 'line',
        label: 'KPI Score',
        data: trendsData.map((data) => data.data),
        fill: false,
        borderWidth: 1,
        borderColor: '#4482FF',
        pointStyle: 'circle', // Customize the point style
        pointBackgroundColor: '#4482FF', // Customize the point color
        pointRadius: 1, // Customize the point radius
      },
      {
        type: 'line',
        label: 'KPI Scores',
        data: trendsData.map((items) => items.avgData),
        fill: false,
        borderWidth: 1,
        borderColor: '#f28e00',
        pointStyle: 'circle', // Customize the point style
        pointBackgroundColor: '#f28e00', // Customize the point color
        pointRadius: 1, // Customize the point radius
      },
      {
        type: 'bar',
        label: 'KPI Score (Bar)',
        data: trendsData.map((data) => data.data),
        backgroundColor: '#4482FF',
        borderColor: '#4482FF',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        external(context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('below', 'no-transform');
          const { $context = {} } = tooltipModel;
          const { tooltipItems = [] } = $context;
          if (tooltipItems && tooltipItems.length) {
            const dateObj = tooltipItems?.[0]?.label ? getMomentDate(tooltipItems?.[0]?.label) : '';
            const label = dateObj.format((view === 'weekly' ? DATE_FORMAT.WEEK_DATE_GRAPH : DATE_FORMAT.MONTH_DATE_GRAPH));
            const average = tooltipItems?.[1]?.raw;
            const actual = tooltipItems?.[0]?.raw;
            const innerHTML = `
              <div style="width: 150px; background-color: rgba(0, 0, 0, 0.7); border-radius: 5px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); padding: 3px;">
                  <div style="padding: 3px; background-color: transparent; border-top-left-radius: 5px; border-top-right-radius: 5px;">
                      <span style="color: white; font-size: 15px;">${label}</span>
                  </div>
                  <hr style="margin: 0; border-color: white;">
                  <div style="padding: 3px;">
                  <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #4482FF;"></span>
                  Actual : ${actual}
                </p>
                <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #f28e00;"></span>
                  Average : ${average}
                </p>
                  </div>
              </div>`;

            tooltipEl.querySelector('table').innerHTML = innerHTML;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';

          const tooltipWidth = tooltipEl.offsetWidth;
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipWidth > position.right) {
            left -= (left + tooltipWidth - position.right);
          }

          tooltipEl.style.left = `${left}px`;

          tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
          tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
          tooltipEl.style.pointerEvents = 'none';
        },

      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        suggestedMin: 0,
        suggestedMax: 100,

      },
    },
  };

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      LineElement,
      PointElement,
      ArcElement,
      Title,
      Tooltip,
      Legend,
      Filler,
    );

    return () => {
      ChartJS.unregister(
        CategoryScale,
        LinearScale,
        BarElement,
        LineElement,
        PointElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
        Filler,
      );
    };
  }, [trendsData, chartData, options]);

  // const handleSetConfig = () => {
  //   navigate(
  //     '/configuration',
  //   );
  // };
  if (trendsData && trendsData.length) {
    return (
      <Box sx={{ width: 84, height: 40 }}>
        <Chart type="bar" data={chartData} options={options} />
      </Box>
    );
  }

  // return (
  //   <Typography>
  //     No Data
  //   </Typography>
  // );
}
