import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {
  Card, Typography, Box,
  LinearProgress,
  Stack,
  IconButton,
} from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { getName } from 'i18n-iso-countries';
import useStyles from '../../../hooks/useStyles';
import styles from '../../../layouts/pages/style';
import RetryDashboardComponent from '../../../components/RetryDashboardComponent/RetryDashboardComponent';
import { CustomNoDataOverlay } from '../../../components/CustomNoDataOverlay';
import { CustomLinearProgress } from '../../../components/CustomLinearProgress';

function GeographyCountryCard({
  data, loading, error, refresh, setCountry,
}) {
  const navigate = useNavigate();
  const classes = useStyles(styles)();
  const CountryContainerStyle = styled(Box)(({ theme }) => ({
    '& .headerCol': {
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.grey[100],
      '& h6': {
        width: '40%',
        padding: theme.spacing(1, 3),
        fontSize: 14,
        '& + h6': {
          width: '60%',
          fontSize: 14,
        },
      },
    },
    '& .countryList': {
      display: 'flex',
      '&:nth-child(odd)': {
        background: theme.palette.grey[100],
      },
      '& h6': {
        fontSize: 13,
      },
    },
  }));

  return (
    <Card className={classes.cardStyles} sx={{ height: '80vh', overflow: 'scroll' }}>
      <CountryContainerStyle>
        {loading ? <LinearProgress /> : null}
        {!loading && !data?.length && !error ? <CustomNoDataOverlay /> : null}
        {!loading && error ? <RetryDashboardComponent refreshHandler={refresh} /> : null}
        {data?.length ? (
          data.map((items) => (
            <Box className="countryList" key={items.organizationCountry}>
              <Box sx={(theme) => ({ width: '100%', padding: theme.spacing(1.5, 3), cursor: 'pointer' })} onClick={() => setCountry(items.organizationCountry ? items.organizationCountry : '')}>
                <Stack direction="row" gap={1}>
                  <ReactCountryFlag
                    countryCode={items.organizationCountry ? items.organizationCountry : ''}
                    style={{ fontSize: '1.3em' }}
                  />
                  <Typography variant="h6">
                    {items?.organizationCountry ? getName(items.organizationCountry, 'en') : ''}
                  </Typography>
                </Stack>

                <CustomLinearProgress value={items.per ? +items.per : 0} />

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography variant="h6">
                      {`Accounts ${items?.UniqueOrgCount ?? ''} (${items.per ? +items.per : 0}%)`}
                    </Typography>
                  </Box>

                  <IconButton
                    variant="contained"
                    sx={{ backgroundColor: 'primary.lighter' }}
                    size="small"
                    color="primary"
                    onClick={() => navigate('/account-list', { state: { filters: { country: [items.organizationCountry], licenceStatus: ['Active'], accountVisibility: true }, showAdvanceFilter: true } })}
                  >
                    <CorporateFareIcon />
                  </IconButton>
                </Stack>
              </Box>
            </Box>
          ))
        ) : null}
      </CountryContainerStyle>
    </Card>
  );
}

export default GeographyCountryCard;
