import {
  Card, CardHeader, Grid, CardContent, Typography, Box, LinearProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFeatureUsageAccounts } from '../../features/FeatureUsage/featureUsageAction';
import useStyles from '../../hooks/useStyles';
import { fCommaSeparated } from '../../utils/formatNumber';
import useAccountStats from './useAccountStats';
import styles from './style';
import { featureUsageModeConst } from '../AccountList/accountListConfig';

export default function AccountStats({ packageId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles(styles)();

  useEffect(() => {
    if (packageId) {
      dispatch(getFeatureUsageAccounts({ packageId }));
    }
  }, [packageId]);

  const {
    accountStatsData,
    currentDate,
    threeMonthsAgo,
    threeMonthsLater,
    fiscalYearStart,
    fiscalYearEnd,
    navigateState,
  } = useAccountStats();

  const {
    activeCount,
    trialCount,
    siteWideSeats,
    totalSeats,
    assignedSeats,
    threeMonthCount,
    fiscalYearCount,
    churnedCount,
    withoutUsageCount,
    upcomingRenewalCount,
    trendingCount,
    decliningCount,
    stableCount,
    riskCount,
    loading,
    error,
  } = accountStatsData;

  return (
    <Box className="tg-account-stats">
      <Grid container item lg={12} sx={{ justifyContent: 'space-between' }}>
        <Grid container item xs={12} sm={6} md={6} lg={2.25}>
          <Card className={classes.cardStyles}>
            <CardHeader title="Total Accounts" className={classes.headerStyles} />
            <CardContent className={classes.contentStyles}>
              {!loading && !error
                ? (
                  <>
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', { state: { filters: { licenceStatus: ['Active'], accountVisibility: true } } })}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {`Active Accounts - ${activeCount ? fCommaSeparated(activeCount) : 0}`}
                    </Typography>
                    {totalSeats > 0 ? (
                      <Typography className={classes.subTextStyles} gutterBottom>
                        {`Seats: Total - ${fCommaSeparated(totalSeats)} | Assigned - ${assignedSeats ? fCommaSeparated(assignedSeats) : 0}`}
                      </Typography>
                    ) : null}
                    {siteWideSeats > 0 ? (
                      <Typography className={classes.subTextStyles} gutterBottom>
                        {`Site Wide Licenses - ${fCommaSeparated(siteWideSeats)}`}
                      </Typography>
                    ) : null}
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', { state: { filters: { licenceStatus: ['Trial'], accountVisibility: true } } })}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {`(In Trial - ${trialCount ? fCommaSeparated(trialCount) : 0})`}
                    </Typography>
                  </>
                )
                : null}
              {loading && !error ? (<LinearProgress />) : null}
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} sm={6} md={6} lg={2.25}>
          <Card className={classes.cardStyles}>
            <CardHeader title="Recently Activated" className={classes.headerStyles} />
            <CardContent className={classes.contentStyles}>
              {!loading && !error
                ? (
                  <>
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', {
                        state: {
                          filters: {
                            activationStartDate: threeMonthsAgo, activationEndDate: currentDate, licenceStatus: ['Active & Trial'], accountVisibility: true,
                          },
                          showAdvanceFilter: true,
                        },
                      })}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {`Last 3 Months - ${threeMonthCount ? fCommaSeparated(threeMonthCount) : 0}`}
                    </Typography>
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', {
                        state: {
                          filters: {
                            activationStartDate: fiscalYearStart, activationEndDate: fiscalYearEnd, licenceStatus: ['Active & Trial'], accountVisibility: true,
                          },
                          showAdvanceFilter: true,
                        },
                      })}
                    >
                      {`Last Fiscal Year - ${fiscalYearCount ? fCommaSeparated(fiscalYearCount) : 0}`}
                    </Typography>
                  </>
                )
                : null}
              {loading && !error ? (<LinearProgress />) : null}
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} sm={6} md={6} lg={2.25}>
          <Card className={classes.cardStyles}>
            <CardHeader title="KPI Score Trend" className={classes.headerStyles} />
            <CardContent className={classes.contentStyles}>
              {!loading && !error
                ? (
                  <>
                    {/* Trending */}
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', {
                        state: {
                          filters: {
                            healthScoreTrend: ['rising'], licenceStatus: ['Active'], accountVisibility: true,
                          },
                          showAdvanceFilter: true,
                        },
                      })}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {`Trending Accounts - ${trendingCount ? fCommaSeparated(trendingCount) : 0}`}
                    </Typography>
                    {/* Stable */}
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', { state: navigateState.stable })}
                    >
                      {`Stable Accounts - ${stableCount ? fCommaSeparated(stableCount) : 0}`}
                    </Typography>
                    {/* Declining */}
                    <Typography
                      className={[classes.textStyles, classes.riskTextStyles]}
                      gutterBottom
                      onClick={() => navigate('/account-list', {
                        state: {
                          filters: {
                            healthScoreTrend: ['falling'], licenceStatus: ['Active'], accountVisibility: true,
                          },
                          showAdvanceFilter: true,
                        },
                      })}
                    >
                      {`Declining Accounts - ${decliningCount ? fCommaSeparated(decliningCount) : 0}`}
                    </Typography>
                    {/* High Risk */}
                    <Typography
                      className={[classes.textStyles, classes.riskTextStyles]}
                      gutterBottom
                      onClick={() => navigate('/account-list', { state: navigateState.risk })}
                    >
                      {`High Risk Accounts - ${riskCount ? fCommaSeparated(riskCount) : 0}`}
                    </Typography>
                  </>
                )
                : null}
              {loading && !error ? (<LinearProgress />) : null}
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} sm={6} md={6} lg={2.25}>
          <Card className={classes.cardStyles}>
            <CardHeader title="Inactive Accounts" className={classes.headerStyles} />
            <CardContent className={classes.contentStyles}>
              {!loading && !error
                ? (
                  <>
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', { state: { filters: { licenceStatus: ['Churned'], accountVisibility: true } } })}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {`Churned Accounts - ${churnedCount ? fCommaSeparated(churnedCount) : 0}`}
                    </Typography>
                    <Typography
                      className={classes.textStyles}
                      gutterBottom
                      onClick={() => navigate('/account-list', { state: { filters: { licenceStatus: ['Active'], featureUsageMode: featureUsageModeConst.DONT_USE, accountVisibility: true } } })}
                    >
                      {`Without Usage - ${withoutUsageCount ? fCommaSeparated(withoutUsageCount) : 0}`}
                    </Typography>
                  </>
                )
                : null}
              {loading && !error ? (<LinearProgress />) : null}
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} sm={6} md={6} lg={2.25}>
          <Card className={classes.cardStyles}>
            <CardHeader title="Upcoming Renewal" className={classes.headerStyles} />
            <CardContent className={classes.contentStyles}>
              {!loading && !error
                ? (
                  <Typography
                    className={classes.textStyles}
                    gutterBottom
                    onClick={() => navigate('/account-list', {
                      state: {
                        filters: {
                          renewalStartDate: currentDate, renewalEndDate: threeMonthsLater, licenceStatus: ['Active & Trial'], accountVisibility: true,
                        },
                        showAdvanceFilter: true,
                      },
                    })}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {`Renewing in Next 3 Months - ${upcomingRenewalCount ? fCommaSeparated(upcomingRenewalCount) : 0}`}
                  </Typography>
                )
                : null}
              {loading && !error ? (<LinearProgress />) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
