import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
// dependency
import { getLocalStorage } from './utils/methods';
import useAuthorization from './hooks/useAuthorization';
import useShareableScreen from './hooks/useShareableScreen';
import usePermissions from './hooks/usePermissions';
// layouts
import { AddEditFeature } from './modules/FeatureManager';
import { AddEditHealthInsights } from './modules/HealthScore';
import Auth from './sections/Auth/Auth';
import AuthLayout from './layouts/auth/AuthLayout';
import DashboardLayout from './layouts/dashboard';
import DashboardAppPage from './pages/DashboardAppPage';
import AccountDetailsPage from './pages/AccountDetailsPage';
import AccountListPage from './pages/AccountListPage';
import GeographiesPage from './pages/GeographiesPage';
import VersionsPage from './pages/VersionsPage';
import CustomObjectPage from './pages/CustomObjectPage';
import FeatureUsagePage from './pages/FeatureUsagePage';
import ActiveUsersMorePage from './pages/ActiveUsersMorePage';
import AccountCustomObjectPage from './pages/AccountCustomObjectPage';
import LogAnalyzerPage from './pages/LogAnalyzerPage';
import FeatureManagerPage from './pages/FeatureManagerPage';
import HealthScoreConfigPage from './pages/HealthScoreConfigPage';
import SfIntegrationPage from './pages/SfIntegrationPage';
import UserManagementPage from './pages/UserManagementPage';
import ErrorBoundary from './components/ErrorBoundaries/FallBack';
import Error404Page from './components/ErrorBoundaries/Error404Page';
import TenantManagementPage from './pages/TenantManagementPage';
import ProfilePage from './pages/ProfilePage';
import ConfigurationPage from './pages/ConfigurationPage';
import TenantDetailsPage from './pages/TenantDetailsPage';
import PackageManagementPage from './pages/PackageManagementPage';
import ScoreInsightsPage from './pages/ScoreInsightsPage';

export default function Router() {
  // Load the ProGPT bot
  useEffect(() => {
    let botContainer = document.getElementById('progpt_container');
    if (!botContainer) {
      botContainer = document.createElement('div');
      botContainer.id = 'progpt_container';
      document.body.appendChild(botContainer);
    }

    const script = document.createElement('script');
    script.src = 'https://app.progpt.biz/embed.js';
    script.async = true;
    script.setAttribute('data-width', '500px');
    script.setAttribute('data-height', '500px');
    script.setAttribute('p_id', '52711c82-dc1b-4f89-a694-48a91f84c63c');
    document.body.appendChild(script);

    return () => {
      botContainer?.remove();
      document.body.removeChild(script);
    };
  }, []);

  const accessToken = getLocalStorage('accessToken');
  const {
    isShareableScreen, tenantId, isSalesforce, organizationId,
  } = useShareableScreen();
  const { filterRoutes } = usePermissions();
  const { userInfo } = useAuthorization();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigateTo = useMemo(() => {
    if (token) {
      localStorage.setItem('idToken', token);
      localStorage.setItem('accessToken', token);
    }
    if (!accessToken) {
      return '/login';
    }
    return '/dashboard';
  }, [accessToken, userInfo, token]);

  const errorRoute = { path: '*', element: accessToken === null ? <Navigate to="/login" /> : <Error404Page title="404" message="Page not found!" /> };

  const authRoutes = {
    element: <AuthLayout />,
    children: [
      { element: <Navigate to={navigateTo} />, index: true },
      { path: '/login', element: accessToken === null ? <Auth type="login" /> : <Navigate to="/" /> },
      { path: '/set-password', element: accessToken === null ? <Auth type="setPassword" /> : <Navigate to="/" /> },
      { path: '/reset-password', element: accessToken === null ? <Auth type="resetPassword" /> : <Navigate to="/" /> },
    ],
  };

  const appRoutes = {
    path: '/',
    page: 'layout',
    element: <DashboardLayout isShareableScreens={isShareableScreen} />,
    children: [
      { element: <Navigate to={navigateTo} />, index: true },
      { path: 'dashboard', page: userInfo?.access === 'superAdmin' ? 'tenantManager' : 'dashboard', element: userInfo?.access === 'superAdmin' ? <TenantManagementPage /> : <DashboardAppPage /> },
      { path: 'user-manager', page: 'userManager', element: <UserManagementPage /> },
      { path: 'tenant-details', page: 'tenantDetails', element: <TenantDetailsPage /> },
      { path: 'geographies', page: 'geographies', element: <GeographiesPage /> },
      { path: 'versions', page: 'versions', element: <VersionsPage /> },
      { path: 'account-list', page: 'accountList', element: <AccountListPage /> },
      { path: 'custom-objects', page: 'customObjects', element: <CustomObjectPage /> },
      { path: 'active-users', page: 'activeUsers', element: <ActiveUsersMorePage /> },
      { path: 'feature-usage', page: 'featureUsage', element: <FeatureUsagePage dashboard /> },
      { path: 'account-page', page: 'accountDashboard', element: <AccountDetailsPage isShareableScreens={isShareableScreen} userInfo={userInfo} tenantId={tenantId} isSalesforce={isSalesforce} organizationId={organizationId} /> },
      { path: 'account-page/active-users', page: 'accountActiveUsers', element: <ActiveUsersMorePage /> },
      { path: 'account-page/feature-usage', page: 'accountFeatureUsage', element: <FeatureUsagePage /> },
      { path: 'account-page/custom-object', page: 'accountCustomObject', element: <AccountCustomObjectPage /> },
      { path: 'kpi-score-configuration', page: 'healthScoreConfig', element: <HealthScoreConfigPage /> },
      { path: 'kpi-score-configuration/new-health-score', page: 'healthScoreConfigCreate', element: <AddEditHealthInsights /> },
      { path: 'kpi-score-configuration/update-health-score/:id', page: 'healthScoreConfigUpdate', element: <AddEditHealthInsights /> },
      { path: 'log-analyzer', page: 'logAnalyzer', element: <LogAnalyzerPage /> },
      { path: 'feature-manager', page: 'featureManager', element: <FeatureManagerPage /> },
      { path: 'feature-manager/new-feature', page: 'featureManagerCreate', element: <AddEditFeature /> },
      { path: 'feature-manager/update-feature/:id', page: 'featureManagerUpdate', element: <AddEditFeature /> },
      { path: 'my-profile', page: 'myProfile', element: <ProfilePage /> },
      { path: 'connect-pbo', page: 'connectPbo', element: <SfIntegrationPage /> },
      { path: 'configuration', page: 'configuration', element: <ConfigurationPage /> },
      { path: 'package-list', page: 'packageList', element: <PackageManagementPage /> },
      { path: 'score-insights', page: 'scoreInsights', element: <ScoreInsightsPage isShareableScreens={isShareableScreen} /> },
    ],
  };

  const allRoutes = useMemo(() => {
    const routes = [authRoutes, errorRoute];
    if (userInfo?.access || isShareableScreen) {
      routes.push(appRoutes);
    }
    return routes;
  }, [userInfo?.access]);

  const filteredRoutes = filterRoutes(allRoutes, userInfo?.access);
  const generatedRoutes = useRoutes(filteredRoutes);

  return <ErrorBoundary>{generatedRoutes}</ErrorBoundary>;
}
