import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessToken } from '../../../features/Auth/authSlice';
import Metatags from '../../../components/MetaTags/MetaTags';
import LoginForm from './LoginForm';
import useLogin from './useLogin';

export default function Login() {
  const {
    initialValues,
    handleSubmit,
    auth,
    showPassword,
    handleTogglePassword,
    loginValidationSchema,
    isRequiredForLogin,
    getUserInfo,
  } = useLogin();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (token) {
      localStorage.setItem('idToken', token);
      localStorage.setItem('accessToken', token);
      const userInfo = getUserInfo();
      localStorage.setItem('persona', userInfo?.persona);
      dispatch(setAccessToken(token));
      navigate('/dashboard');
    }
  }, [token]);

  return (
    <>
      <Metatags metaTagName="Login | ISV Insights" />
      <LoginForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        auth={auth}
        showPassword={showPassword}
        handleTogglePassword={handleTogglePassword}
        validationSchema={loginValidationSchema}
        isRequiredForLogin={isRequiredForLogin}
      />
    </>
  );
}
