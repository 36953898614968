/* eslint-disable camelcase */
import {
  Box, Button, Grid, Slider, Typography, styled,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { ConfirmDialogBox } from '../../../components/CustomDialogBox';
import { CustomIcon } from '../../../components/CustomIcon';
import {
  defaultFilterValue, deleteProtectedView, featureUsageModeConst, hasDefaultFilters, healthScoreMarks, marks,
} from '../../../sections/AccountList/accountListConfig';
import { DatePicker, SearchBar, SelectBox } from '../../../components/FormComponents';
import useAccountListFilters from './useAccountListFilters';
import CustomFilterDialog from './CustomFilter/CustomFilterDialog';

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.secondary.mainColor,
}));

const customDesign = {
  borderColor: '#3366FF',
  borderWidth: 2,
  borderRadius: '8px',
  borderStyle: 'solid',
  backgroundColor: 'aliceblue',
  svg: { color: (theme) => theme.palette.grey[500] },
};

function AccountListFilters(props) {
  const {
    classes,
    accountList,
    featureList,
    selectedFilters,
    setSelectedFilters,
    resetFilters,
    toggleAdvanceFilters,
    setToggleAdvanceFilters,
    openDialog,
    setOpenDialog,
    activePackage,
    selectedView,
    setSelectedView,
    findSelectedView,
  } = props;

  const {
    handleFilterChange,
    dropdown,
    type,
    handleClickCreateView,
    handleClickUpdateView,
    openDeleteDialog,
    setOpenDeleteDialog,
    handleDeleteCustomFilter,
  } = useAccountListFilters({
    accountList,
    featureList,
    setSelectedFilters,
    setOpenDialog,
    resetFilters,
    setToggleAdvanceFilters,
    selectedView,
    setSelectedView,
    findSelectedView,
  });

  const isDefaultOrWatchList = deleteProtectedView.includes(selectedView);

  return (
    <>
      {/* Normal filters */}
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sm={6} lg={4.8} sx={{ alignSelf: 'center' }}>
          <SearchBar
            label="Account Name"
            value={selectedFilters.searchQuery}
            data={selectedFilters.searchQuery}
            onChange={(e) => handleFilterChange('searchQuery', e.target.value)}
            onClear={() => handleFilterChange('searchQuery', '')}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Package Version"
            value={selectedFilters.packageVersion}
            data={dropdown.packageVersionOptions}
            onChange={(e) => handleFilterChange('packageVersion', e.target.value)}
            highlighted={selectedFilters.packageVersion.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Licence Assignment"
            value={selectedFilters.licenceAssignment}
            data={dropdown.licenceAssignmentOptions}
            onChange={(e) => handleFilterChange('licenceAssignment', e.target.value)}
            highlighted={selectedFilters.licenceAssignment.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Licence Status"
            value={selectedFilters.licenceStatus}
            data={dropdown.licenceStatusOptions}
            onChange={(e) => handleFilterChange('licenceStatus', e.target.value)}
            highlighted={selectedFilters.licenceStatus.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            title="Feature Usage"
            value={selectedFilters.featureUsageFilter}
            data={dropdown.featureUsageFilterOptions
              ? [defaultFilterValue,
                ...dropdown.featureUsageFilterOptions.map(({ feature_id, feature_name }) => ({ id: feature_id, value: feature_id, name: feature_name })),
              ]
              : null}
            onChange={(e) => handleFilterChange('featureUsageFilter', e.target.value)}
            highlighted={selectedFilters.featureUsageFilter !== defaultFilterValue && selectedFilters.featureUsageFilter}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            title="Feature Usage Mode"
            value={selectedFilters.featureUsageMode}
            data={dropdown.featureUsageModeOptions
              ? [defaultFilterValue, ...dropdown.featureUsageModeOptions]
              : null}
            onChange={(e) => handleFilterChange('featureUsageMode', e.target.value)}
            highlighted={(selectedFilters.featureUsageFilter !== defaultFilterValue && selectedFilters.featureUsageFilter)
              || (selectedFilters.featureUsageMode === featureUsageModeConst.DONT_USE)}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Organization Edition"
            value={selectedFilters.organisationEdition}
            data={dropdown.organisationEditionOptions}
            onChange={(e) => handleFilterChange('organisationEdition', e.target.value)}
            highlighted={selectedFilters.organisationEdition.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Salesforce Instance"
            value={selectedFilters.salesforceInstance}
            data={dropdown.salesforceInstanceOptions}
            onChange={(e) => handleFilterChange('salesforceInstance', e.target.value)}
            highlighted={selectedFilters.salesforceInstance.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            title="Account Visibility"
            value={selectedFilters.accountVisibility}
            data={[defaultFilterValue, ...dropdown.accountVisibilityOptions]}
            onChange={(e) => handleFilterChange('accountVisibility', e.target.value)}
            highlighted={selectedFilters.accountVisibility !== defaultFilterValue}
          />
        </Grid>
      </Grid>

      {/* Advanced filters */}
      {toggleAdvanceFilters && (
      <Grid item container xs={12} gap={2} alignItems="end">
        <Grid item xs={12} sm={6} lg={3.6}>
          <DatePicker
            label="Activation Date"
            fromDate={selectedFilters.activationStartDate}
            toDate={selectedFilters.activationEndDate}
            handleStartDateChange={(newValue) => handleFilterChange('activationStartDate', newValue)}
            handleEndDateChange={(newValue) => handleFilterChange('activationEndDate', newValue)}
            customDesign={selectedFilters.activationStartDate || selectedFilters.activationEndDate ? customDesign : undefined}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3.6}>
          <DatePicker
            label="Renewal Date"
            fromDate={selectedFilters.renewalStartDate}
            toDate={selectedFilters.renewalEndDate}
            handleStartDateChange={(newValue) => handleFilterChange('renewalStartDate', newValue)}
            handleEndDateChange={(newValue) => handleFilterChange('renewalEndDate', newValue)}
            customDesign={selectedFilters.renewalStartDate || selectedFilters.renewalEndDate ? customDesign : undefined}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Country"
            value={selectedFilters.country}
            data={dropdown.packageCountryOptions}
            onChange={(e) => handleFilterChange('country', e.target.value)}
            highlighted={selectedFilters.country.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2}>
          <SelectBox
            multiple
            title="Language"
            value={selectedFilters.language}
            data={dropdown.packageLanguageOptions}
            onChange={(e) => handleFilterChange('language', e.target.value)}
            highlighted={selectedFilters.language.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2.4}>
          <SelectBox
            multiple
            title="KPI Score Trend"
            value={selectedFilters.healthScoreTrend}
            data={dropdown.healthScoreTrendOptions}
            onChange={(e) => handleFilterChange('healthScoreTrend', e.target.value)}
            highlighted={selectedFilters.healthScoreTrend.length}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2.4}>
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ pl: '5px', mb: 1, fontSize: 14 }}>Seats</Typography>
            <Box sx={{ pl: 2 }}>
              <IOSSlider
                getAriaValueText={(value) => `${value}°C`}
                marks={marks}
                min={0}
                max={150}
                disableSwap
                value={selectedFilters.range}
                onChange={(e, newVal, activeThumb) => handleFilterChange('range', e.target.value, activeThumb)}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={2.4}>
          <Box sx={{ width: '100%', paddingLeft: '20px' }}>
            <Typography sx={{ pl: '5px', mb: 1, fontSize: 14 }}>Score</Typography>
            <Box sx={{ pl: 2 }}>
              <IOSSlider
                getAriaValueText={(value) => `${value}°C`}
                marks={healthScoreMarks}
                step={20}
                min={0}
                max={100}
                disableSwap
                value={selectedFilters.healthScoreRange}
                onChange={(e, newVal, activeThumb) => handleFilterChange('healthScoreRange', e.target.value, activeThumb)}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      )}

      {/* Filter actions */}
      <Grid item container className={classes.actionStyles} xs={12}>
        <Grid container item xs="auto" gap={1} className="tg-custom-view">
          <CustomIcon tooltip="Delete View" Icon={DeleteIcon} hidden={isDefaultOrWatchList} onClick={setOpenDeleteDialog} />
          <CustomIcon tooltip="Update View" Icon={EditNoteIcon} onClick={handleClickUpdateView} hidden={isDefaultOrWatchList} />
          <CustomIcon tooltip="Save View As..." Icon={PlaylistAddIcon} onClick={handleClickCreateView} hidden={hasDefaultFilters(selectedFilters)} />
        </Grid>

        <Grid item xs={12} sm={5.8} md={3} lg={2}>
          <Button size="large" variant="outlined" onClick={resetFilters}>
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} sm={5.8} md={3} lg={2}>
          <Button size="large" variant="contained" onClick={setToggleAdvanceFilters}>
            {toggleAdvanceFilters ? 'Close' : 'Advance Filter'}
          </Button>
        </Grid>
      </Grid>

      <CustomFilterDialog
        type={type}
        open={openDialog}
        handleClose={setOpenDialog}
        selectedFilters={selectedFilters}
        activePackage={activePackage}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        findSelectedView={findSelectedView}
      />

      <ConfirmDialogBox
        title="Delete View"
        message="Are you sure you want to delete this view?"
        openDialog={openDeleteDialog}
        onClose={setOpenDeleteDialog}
        onConfirm={handleDeleteCustomFilter}
      />
    </>
  );
}

export default AccountListFilters;
