import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getLocalStorage, getUserRole, parseStrObj } from '../utils/methods';

export default function useAuthorization() {
  const user = useSelector((state) => state.auth.user);

  const getDecodedToken = (type) => {
    const accessToken = user?.accessToken || getLocalStorage('accessToken');
    const idToken = user?.idToken || getLocalStorage('idToken');
    const token = type === 'access' ? accessToken : idToken;

    if (!token) return false;
    const decodedToken = jwtDecode(token);
    if (!decodedToken) return false;
    return decodedToken;
  };

  const isTokenValidFn = () => {
    const decodedAccessToken = getDecodedToken('access');
    const expirationTime = moment.unix(decodedAccessToken.exp);
    const currentTime = moment().unix();
    if (expirationTime.isAfter(currentTime)) return true;
    return false;
  };

  const getUserInfo = () => {
    const decodedAccessToken = getDecodedToken('id');

    if (decodedAccessToken) {
      const tenantId = decodedAccessToken['custom:tenant'];
      const groupsPayload = decodedAccessToken['cognito:groups'];
      const persona = decodedAccessToken['custom:persona'] ?? 'general';
      const accessPayload = parseStrObj(decodedAccessToken['custom:access']);
      let cognitoAccess;
      if (typeof accessPayload === 'string') {
        cognitoAccess = accessPayload;
      } else if (typeof accessPayload === 'object') {
        cognitoAccess = accessPayload[tenantId];
      }
      const cognitoUser = getUserRole(cognitoAccess);
      const cognitoGroups = getUserRole(groupsPayload);

      const userInfo = {
        email: decodedAccessToken.email,
        name: decodedAccessToken.name,
        tenantId,
        access: cognitoUser.access,
        role: cognitoUser.role,
        group: cognitoGroups.access,
        persona,
      };

      return userInfo;
    }
    return null;
  };

  const userInfo = useMemo(() => getUserInfo(), [user]);

  return {
    isTokenValidFn, getUserInfo, userInfo,
  };
}
