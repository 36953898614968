import {
  Box, Grid, IconButton, Tooltip,
} from '@mui/material';
import Lottie from 'lottie-react';
import { Masonry } from '@mui/lab';
import { DASHBOARD_CARDS_ID } from '../../utils/constants';
import { AccountStats } from '../AccountStats';
import { CustomFilterAlert, PersonaDropdown, StatusAndExpiry } from '../../modules/Dashboard';
import { PackageSelection } from '../../modules/Accounts/components/PackageSelection';
import useDashboard from './useDashboard';
import { HourGlassJson } from './HourGlassJson';

export default function Dashboard({ isNewProp }) {
  const {
    personaList,
    selectedPersona,
    handlePersonaChange,
    visibleBoxIds,
    boxComponents,
    packageList,
    activePackage,
    handleDropdownChange,
    startTourState,
  } = useDashboard();

  return (
    <Grid container gap={2}>
      <Grid container item xs={12} justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row" gap={2}>
          <PackageSelection
            startTourState={startTourState}
            dropDownList={packageList.packages}
            activeDropdown={activePackage}
            handleChange={handleDropdownChange}
          />
          <StatusAndExpiry />
        </Box>
        <Box display="flex" flexDirection="row" gap={2}>
          <PersonaDropdown
            personaList={personaList}
            selectedPersona={selectedPersona}
            handlePersonaChange={handlePersonaChange}
          />
          {isNewProp && (
            <Tooltip title="Data is coming, Please wait.">
              <IconButton>
                <Lottie animationData={HourGlassJson} loop autoplay style={{ width: 25, height: 25 }} />
              </IconButton>
            </Tooltip>
          )}
          <CustomFilterAlert activePackage={activePackage} />
        </Box>
      </Grid>
      {visibleBoxIds.includes(DASHBOARD_CARDS_ID.ACCOUNT_STATS) && (
        <Grid item xs={12}>
          <AccountStats packageId={activePackage.packageId} />
        </Grid>
      )}
      <Masonry columns={{ xs: 1, sm: 2 }}>
        {visibleBoxIds.map((boxId) => boxComponents[boxId] && <Box key={boxId}>{boxComponents[boxId]}</Box>)}
      </Masonry>
    </Grid>
  );
}
