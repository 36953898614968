import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCustomFilterView } from '../../../features/CustomFilterView/customFilterViewAction';
import { openSnackbar } from '../../../features/SnackBar/snackBarSlice';
import useToggle from '../../../hooks/useToggle';
import {
  accountStatus, compareVersions, defaultFilterValue, featureUsageModeConst, filterByName, regionNamesByCountryCode, regionNamesByLanguageCode,
} from '../../../sections/AccountList/accountListConfig';

function useAccountListFilters(props) {
  const {
    accountList,
    featureList,
    setSelectedFilters,
    setOpenDialog,
    resetFilters,
    setToggleAdvanceFilters,
    selectedView,
    setSelectedView,
    findSelectedView,
  } = props;

  const dispatch = useDispatch();

  // Handle filter change function
  const filterRange = (filterName, value, activeThumb) => {
    if (activeThumb === 0) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [Math.min(value[0], prevFilters.range[1]), prevFilters.range[1]],
      }));
    } else if (activeThumb === 1) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [prevFilters.range[0], Math.max(value[1], prevFilters.range[0])],
      }));
    }
  };

  const filterHealthScore = (filterName, value, activeThumb) => {
    if (activeThumb === 0) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [Math.min(value[0], prevFilters.healthScoreRange[1] - 20), prevFilters.healthScoreRange[1]],
      }));
    } else if (activeThumb === 1) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [prevFilters.healthScoreRange[0], Math.max(value[1], prevFilters.healthScoreRange[0] + 20)],
      }));
    }
  };

  const filterFeatureUsageMode = (filterName, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
      ...(value === defaultFilterValue && { featureUsageFilter: defaultFilterValue }),
    }));
  };

  const filterFeatureUsage = (filterName, value) => {
    const featureUsageModeValue = value === defaultFilterValue ? defaultFilterValue : featureUsageModeConst.USE;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
      featureUsageMode: featureUsageModeValue,
    }));
  };

  const filterDefault = (filterName, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleFilterChange = useCallback((filterName, value, activeThumb) => {
    switch (filterName) {
      case 'range':
        filterRange(filterName, value, activeThumb);
        break;

      case 'healthScoreRange':
        filterHealthScore(filterName, value, activeThumb);
        break;

      case 'featureUsageFilter':
        filterFeatureUsage(filterName, value);
        break;

      case 'featureUsageMode':
        filterFeatureUsageMode(filterName, value);
        break;

      default:
        filterDefault(filterName, value);
    }
  }, []);

  // Generate dropdown
  const dropdown = useMemo(() => {
    const packageVersionOptions = [];
    const salesforceInstanceOptions = [];
    let packageCountryOptions = [];
    let packageLanguageOptions = [];
    const organisationEditionOptions = [];
    const featureUsageModeOptions = Object.values(featureUsageModeConst);
    const licenceStatusOptions = Object.values(accountStatus);
    const licenceAssignmentOptions = ['Site Wide License', 'Less than 25%', '25% - 50%', '50% - 75%', '75% - 100%'];
    const accountVisibilityOptions = [{ id: 1, value: true, name: 'Included' }, { id: 2, value: false, name: 'Excluded' }];
    const healthScoreTrendOptions = [{ id: 1, value: 'stable', name: 'Stable' }, { id: 2, value: 'rising', name: 'Trending' }, { id: 3, value: 'falling', name: 'Declining' }];
    let featureUsageFilterOptions = [];

    if (accountList && accountList.length) {
      accountList.forEach((element) => {
        if (element.packageVersion) {
          if (!packageVersionOptions.includes(element.packageVersion)) {
            packageVersionOptions.push(element.packageVersion);
          }
        }
        if (element.salesforceInstance) {
          if (!salesforceInstanceOptions.includes(element.salesforceInstance)) {
            salesforceInstanceOptions.push(element.salesforceInstance);
          }
        }
        if (element.countryCode) {
          if (!packageCountryOptions.includes(element.countryCode)) {
            packageCountryOptions.push({ id: element.countryCode, value: element.countryCode, name: regionNamesByCountryCode.of(element.countryCode) });
          }
        }
        if (element.languageLocale) {
          if (!packageLanguageOptions.includes(element.languageLocale)) {
            packageLanguageOptions.push({ id: element.languageLocale, value: element.languageLocale, name: regionNamesByLanguageCode.of(element.languageLocale.replace('_', '-')) });
          }
        }
        if (element.orgEdition) {
          if (!organisationEditionOptions.includes(element.orgEdition)) {
            organisationEditionOptions.push(element.orgEdition);
          }
        }
      });
    }

    if (featureList && featureList.data && featureList.data.length) {
      featureUsageFilterOptions = featureList.data;
    }

    packageVersionOptions.sort(compareVersions);
    packageCountryOptions = filterByName(packageCountryOptions);
    packageLanguageOptions = filterByName(packageLanguageOptions);

    return {
      accountVisibilityOptions,
      healthScoreTrendOptions,
      licenceAssignmentOptions,
      packageVersionOptions,
      licenceStatusOptions,
      salesforceInstanceOptions,
      packageCountryOptions,
      packageLanguageOptions,
      featureUsageFilterOptions,
      featureUsageModeOptions,
      organisationEditionOptions,
    };
  }, [accountList, featureList]);

  // Handle custom view
  const [type, setType] = useState('create');
  const [openDeleteDialog, setOpenDeleteDialog] = useToggle();

  const handleClickCreateView = () => {
    setType('create');
    setOpenDialog();
  };

  const handleClickUpdateView = () => {
    setType('update');
    setOpenDialog();
  };

  const handleDeleteCustomFilter = () => {
    const view = findSelectedView(selectedView);
    if (view) {
      dispatch(deleteCustomFilterView({ id: view?.id })).then((res) => {
        if (res?.payload?.status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message: 'Custom Filter deleted successfully', type: 'success' }));
          resetFilters();
          setToggleAdvanceFilters(false);
          setSelectedView(defaultFilterValue);
        } else {
          dispatch(openSnackbar({ open: true, message: 'Failed to delete Custom Filter', type: 'error' }));
        }
      });
    }
    setOpenDeleteDialog();
  };

  return {
    handleFilterChange,
    dropdown,
    type,
    handleClickCreateView,
    handleClickUpdateView,
    openDeleteDialog,
    setOpenDeleteDialog,
    handleDeleteCustomFilter,
  };
}

export default useAccountListFilters;
