import { useCallback, useMemo, useState } from 'react';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PersonIcon from '@mui/icons-material/Person';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import PaymentsIcon from '@mui/icons-material/Payments';
import CampaignIcon from '@mui/icons-material/Campaign';
import { fetchWithAuth } from '../../../utils/httpRequest';

function usePersona() {
  const persona = localStorage.getItem('persona') ?? 'general';
  const [selectedPersona, setSelectedPersona] = useState(persona);

  const personaList = useMemo(() => [
    {
      name: 'General',
      description: 'All your executive information in one place',
      icon: WidgetsIcon,
      value: 'general',
    },
    {
      name: 'Product Owner',
      description: 'Top Features and fresh Releases performance',
      icon: PersonIcon,
      value: 'productOwner',
    },
    {
      name: 'Customer Executive',
      description: 'Stats & Health for on-boarding, at-risk accounts etc',
      icon: HeadsetMicIcon,
      value: 'customerExecutive',
    },
    {
      name: 'Revenue Operations',
      description: 'Handle potential churns, cash on upcoming Renewals',
      icon: PaymentsIcon,
      value: 'revenueOperations',
    },
    {
      name: 'Marketing',
      description: 'Keep a Pulse on the Product Market fit and Usage',
      icon: CampaignIcon,
      value: 'marketing',
    },
  ], []);

  const handlePersonaChange = useCallback(async (newValue) => {
    setSelectedPersona(newValue);
    localStorage.setItem('persona', newValue);
    await fetchWithAuth('/update-persona', { method: 'POST', body: { persona: newValue } });
  }, []);

  return { personaList, selectedPersona, handlePersonaChange };
}

export default usePersona;
