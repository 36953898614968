const HEALTH_SCORE_TARGET_METRIC = {
  MONTHLY_ACTIVE_USERS: 'Monthly Active Users',
  MONTHLY_ACTIVE_USERS_BASELINE: 'Monthly Active Users % (relative to baseline users)', // baselineUsers, percent
  OPERATIONS: 'Number of Operations',
  OPERATIONS_AVERAGE: 'Number of Operations (average per sold seats)', // totalSeats, average
  OPERATIONS_BASELINE_AVERAGE: 'Number of Operations (average per baseline users)', // baselineUsers, average
  OPERATIONS_PER_FEATURE_USER_AVERAGE: 'Number of Operations (average per feature users)',
  UNIQUE_USERS: 'Unique Users',
  UNIQUE_USERS_SEATS: 'Unique Users % (relative to sold seats)', // totalSeats, percent
  UNIQUE_USERS_BASELINE: 'Unique Users % (relative to baseline users)', // baselineUsers, percent
};

const healthScoreTargetMetricPackage = [
  {
    id: HEALTH_SCORE_TARGET_METRIC.MONTHLY_ACTIVE_USERS,
    value: HEALTH_SCORE_TARGET_METRIC.MONTHLY_ACTIVE_USERS,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.MONTHLY_ACTIVE_USERS_BASELINE,
    value: HEALTH_SCORE_TARGET_METRIC.MONTHLY_ACTIVE_USERS_BASELINE,
  },
];

const healthScoreTargetMetricFeature = [
  {
    id: HEALTH_SCORE_TARGET_METRIC.OPERATIONS,
    value: HEALTH_SCORE_TARGET_METRIC.OPERATIONS,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.OPERATIONS_AVERAGE,
    value: HEALTH_SCORE_TARGET_METRIC.OPERATIONS_AVERAGE,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.OPERATIONS_BASELINE_AVERAGE,
    value: HEALTH_SCORE_TARGET_METRIC.OPERATIONS_BASELINE_AVERAGE,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.OPERATIONS_PER_FEATURE_USER_AVERAGE,
    value: HEALTH_SCORE_TARGET_METRIC.OPERATIONS_PER_FEATURE_USER_AVERAGE,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.UNIQUE_USERS,
    value: HEALTH_SCORE_TARGET_METRIC.UNIQUE_USERS,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.UNIQUE_USERS_SEATS,
    value: HEALTH_SCORE_TARGET_METRIC.UNIQUE_USERS_SEATS,
  },
  {
    id: HEALTH_SCORE_TARGET_METRIC.UNIQUE_USERS_BASELINE,
    value: HEALTH_SCORE_TARGET_METRIC.UNIQUE_USERS_BASELINE,
  },
];

export { healthScoreTargetMetricPackage, healthScoreTargetMetricFeature };
