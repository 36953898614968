import { useState } from 'react';
import {
  FormControl, MenuItem, Select, Stack, Typography, Paper, Grid, Box,
} from '@mui/material';

function PersonaDropdown({ personaList, selectedPersona, handlePersonaChange }) {
  const [open, setOpen] = useState(false); // 🔹 Track open state

  return (
    <FormControl>
      <Select
        value={selectedPersona}
        open={open} // 🔹 Control opening
        onOpen={() => setOpen(true)} // 🔹 Manually open
        onClose={() => setOpen(false)} // 🔹 Manually close
        onChange={(event) => {
          handlePersonaChange(event.target.value);
          setOpen(false); // 🔹 Close after selecting
        }}
        renderValue={(selected) => {
          const selectedItem = personaList.find((item) => item.value === selected);
          return selectedItem ? (
            <Stack direction="row" gap={1} alignItems="center">
              <selectedItem.icon fontSize="small" />
              {selectedItem.name}
            </Stack>
          ) : '';
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              width: { xs: 300, sm: 900 },
              padding: 1,
              borderRadius: 2,
              boxShadow: 3,
            },
          },
        }}
        sx={{
          background: (theme) => theme.palette.grey[200],
          fontSize: '1.2rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        <Paper sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            {personaList.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <MenuItem
                  value={item.value}
                  onClick={() => {
                    handlePersonaChange(item.value);
                    setOpen(false); // 🔹 Close menu after selection
                  }}
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: 2,
                    borderRadius: 1,
                    transition: 'background 0.2s, color 0.2s',
                    backgroundColor: selectedPersona === item.value ? theme.palette.primary.main : 'transparent',
                    color: selectedPersona === item.value ? theme.palette.primary.contrastText : theme.palette.text.primary,
                    '&:hover': selectedPersona === item.value
                      ? { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }
                      : { backgroundColor: theme.palette.primary.lighter, color: theme.palette.primary.dark },
                  })}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box
                      component="span"
                      sx={{
                        display: 'flex',
                        color: selectedPersona === item.value ? 'white' : 'black',
                        transition: 'color 0.2s',
                      }}
                    >
                      <item.icon fontSize="small" />
                    </Box>
                    <Typography fontWeight={600}>{item.name}</Typography>
                  </Stack>
                  <Typography
                    fontSize="small"
                    sx={{
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      width: '100%',
                    }}
                  >
                    {item.description}
                  </Typography>

                </MenuItem>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Select>
    </FormControl>
  );
}

export default PersonaDropdown;
