import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getHighRiskAccountConfig } from '../../features/Configuration/configurationAction';
import { getCustomFilterViewList } from '../../features/CustomFilterView/customFilterViewAction';
import { getFeatureUsageAccounts } from '../../features/FeatureUsage/featureUsageAction';
import { AccountListHeader, AccountListFilters } from '../../modules/AccountList';
import { getAccountsList, getFeaturesList } from '../../features';
import styles from '../../layouts/pages/style';
import useStyles from '../../hooks/useStyles';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import useAccountList from './useAccountList';
import AccountDetailsTable from '../@dashboard/account-details/accountDetailsTable';
import { defaultFilterValue } from './accountListConfig';
import { getCustomAlertDetails } from '../../features/CustomAlert/customAlertAction';

function AccountList({ permissions }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    filters = {},
    showAdvanceFilter = false,
    isNavigatedFromRiskAccount = false,
    selectedAlert = null,
    watchListItem,
  } = location?.state || { filters: { accountVisibility: true, licenceStatus: ['Active'] } };
  const hookParams = {
    filters, showAdvanceFilter, isNavigatedFromRiskAccount, selectedAlert, watchListItem,
  };

  const {
    accountList,
    filteredAccounts,
    featureList,
    selectedFilters,
    setSelectedFilters,
    resetFilters,
    toggleAdvanceFilters,
    setToggleAdvanceFilters,
    findSelectedView,
    openDialog,
    setOpenDialog,
    selectedView,
    setSelectedView,
  } = useAccountList(hookParams);

  const { packageList, activePackage, handleDropdownChange } = usePackageSelection();
  const { packages } = packageList;

  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getFeaturesList({ packageId: activePackage.packageId }));
      dispatch(getHighRiskAccountConfig({ packageId: activePackage.packageId }));
      dispatch(getCustomFilterViewList({ packageId: activePackage.packageId }));
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
      dispatch(getCustomAlertDetails({ pkgId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  useEffect(() => {
    if (activePackage?.packageId && selectedFilters?.featureUsageMode !== defaultFilterValue) {
      dispatch(getFeatureUsageAccounts({
        packageId: activePackage.packageId,
        usageMode: selectedFilters.featureUsageMode,
        ...(selectedFilters.featureUsageFilter !== defaultFilterValue && { featureId: selectedFilters?.featureUsageFilter }),
      }));
    }
  }, [activePackage?.packageId, selectedFilters]);

  const classes = useStyles(styles)();
  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <AccountListHeader
          classes={classes}
          accountList={filteredAccounts}
          packages={packages}
          handleDropdownChange={handleDropdownChange}
          activePackage={activePackage}
          setSelectedFilters={setSelectedFilters}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          findSelectedView={findSelectedView}
          setToggleAdvanceFilters={setToggleAdvanceFilters}
        />
      </Grid>

      <Grid item container xs={12} className={['tg-filters', classes.cardStyles]} gap={2}>
        <AccountListFilters
          classes={classes}
          accountList={accountList?.data}
          featureList={featureList}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          resetFilters={resetFilters}
          toggleAdvanceFilters={toggleAdvanceFilters}
          setToggleAdvanceFilters={setToggleAdvanceFilters}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          activePackage={activePackage}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          findSelectedView={findSelectedView}
        />
      </Grid>

      <Grid item xs={12} className={classes.tableStyles}>
        {filteredAccounts && filteredAccounts.length ? <AccountDetailsTable customFilterData={customFilterData} AccountTableData={filteredAccounts} packageId={activePackage?.packageId} permissions={permissions} /> : null}
      </Grid>
    </Grid>
  );
}
export default AccountList;
