import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as Yup from 'yup';
import useAuthorization from '../../../hooks/useAuthorization';
import { openSnackbar } from '../../../features/SnackBar/snackBarSlice';
import { loginUser } from '../../../features/Auth/authAction';

export default function useLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [initialValues, setInitialValues] = useState({ email: '' });

  const { getUserInfo } = useAuthorization();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const isRequiredForLogin = (name) => {
    switch (name) {
      case 'login':
        return auth.user?.tenant || auth.user?.tenantList;

      case 'multiTenant':
        return !!auth.user?.tenantList;

      case 'singleTenant':
        return !!auth.user?.tenant;

      case 'loginType':
      {
        if (auth.user?.tenant || auth.user?.tenantList) {
          return 'loginAccount';
        }
        return 'checkEmail';
      }

      default:
        return false;
    }
  };

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    ...(isRequiredForLogin('login') && {
      password: Yup.string().required('Password is required'),
    }),
    ...(isRequiredForLogin('') && {
      tenant: Yup.string().required('Choose a tenant to login'),
    }),
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values) => {
    const dataObj = { ...values, type: isRequiredForLogin('loginType') };

    dispatch(loginUser(dataObj)).then((res) => {
      const { error, payload: { code, message, data } } = res;

      if (code === 'MULTI_TENANT') {
        dispatch(openSnackbar({ open: true, message, type: 'info' }));
        setInitialValues({
          email: values.email,
          password: '',
          tenant: data?.tenantList?.[0]?.value ?? '',
        });
        return;
      }

      if (code === 'LOGIN_ACCOUNT') {
        dispatch(openSnackbar({ open: true, message, type: 'info' }));
        setInitialValues({
          email: values.email,
          password: '',
          tenant: data?.tenant ?? '',
        });
        return;
      }

      if (code === 'NEW_USER') {
        dispatch(openSnackbar({ open: true, message, type: 'info' }));
        navigate('/set-password', { state: values });
        return;
      }

      if (!error) {
        dispatch(openSnackbar({ open: true, message, type: 'success' }));
        const userInfo = getUserInfo();
        localStorage.setItem('persona', userInfo?.persona);
        navigate('/');
        return;
      }

      dispatch(openSnackbar({ open: true, message, type: 'error' }));
    });
  };

  return {
    initialValues,
    auth,
    handleSubmit,
    loginValidationSchema,
    showPassword,
    handleTogglePassword,
    isRequiredForLogin,
    getUserInfo,
  };
}
